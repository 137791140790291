import React, { useState, useEffect } from 'react'

export default function usePersistedState<S>(
  key: string,
  defaultValue: S | (() => S)
): [S, React.Dispatch<React.SetStateAction<S | undefined>>] {
  const [state, setState] = useState()

  useEffect(() => {
    const getTheValue = async () => {
      try {
        const storedValue = localStorage.getItem(key)

        if (storedValue !== null) {
          // passed storedValue will always be a string, no matter the defaultValue type
          setState(JSON.parse(storedValue))
        } else {
          // passed defaultValue type is based on variable type
          setState(defaultValue)
        }
      } catch (error) {
        console.log(`Error getting persisted state - key: ${key} ${error}`)
      }
    }

    getTheValue()
  }, [])

  useEffect(() => {
    const setTheValue = async () => {
      try {
        if (state) {
          localStorage.setItem(key, JSON.stringify(state))
        }
      } catch (error) {
        console.log(
          `Error setting persisting state - key: ${key} value: ${state} ${error}`
        )
      }
    }

    setTheValue()
  }, [key, state])

  return [state, setState]
}
