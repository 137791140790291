import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css'

import { About } from './pages/About'
import { NotFound } from './components/NotFound'

import { Ticker } from './components/Ticker'
import { AuthProvider } from './context/AuthContext'

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path={`ticker/:key/:tickerState/:tickerSport/:tickerType`}
            element={<Ticker />}
          />

          <Route path={``} element={<About />} />

          <Route path={`*`} element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
