import React, { useState, useEffect } from 'react'

import { Transition } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const InvalidOneLine = props => {
  const { message, logo } = props

  return (
    <>
      <div className="w-full h-10 flex justify-between overflow-hidden text-sm font-normal text-white  bg-gray-900  ">
        {/* Left side content  */}
        <div className="flex">
          {/* Ticker logo / image */}
          <div className="flex items-center w-40 ml-0 bg-blue-700 border-t border-blue-600">
            <img src={logo} className=" px-3" alt="logo" />
          </div>
          {/* Ticker information wrapper */}
          <div className="flex items-center border-t border-gray-800">
            {/* Message */}
            <div className="px-4 text-lg tracking-wide">{`${message}`}</div>
          </div>
        </div>
        {/* Right side content */}
        <div className="flex">
          {/* vertical line */}
          <div className="border-r border-yellow-300" />
          {/* Advertisement  */}
          {/* <div className="w-96 bg-gray-800"></div> */}
          <div className="flex items-center ml-0 bg-black border-t border-blue-600"></div>
        </div>
      </div>
    </>
  )
}
