import React, { useEffect } from 'react'

export const NotFound = () => {
  useEffect(() => {
    console.log('Not Found screen')
  }, [])

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Oops! Looks like you just went out of bounds!
        </h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}

        {/* /End replace */}
      </div>
    </>
  )
}
