// import { LightningBoltIcon } from '@heroicons/react/outline'

// import iconSD from '../assets/sdscores-icon.png'
// import iconMN from '../assets/mnscores-icon.png'
// import screenshotSD from '../assets/SDscreenshot.png'
// import screenshotMN from '../assets/MNscreenshot.png'
// import imgFootball from '../assets/football.png'
// import imgVolleyball from '../assets/volleyball.png'

export const About = () => {
  return (
    <>
      <main className="flex-1 overflow-y-auto">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="relative bg-white rounded-lg overflow-hidden">
            <div className="max-w-7xl mx-auto">
              <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                  <div className="sm:text-center lg:text-left">
                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                      <span className="block xl:inline">
                        The premier network for
                      </span>{' '}
                      <span className="block xl:inline">high school</span>{' '}
                      <span className="block text-blue-600 xl:inline">
                        sports fans
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                      Consult ScoreFeed for information on displaying a live
                      score ticker on your broadcast.
                    </p>
                  </div>
                </main>
              </div>
            </div>
          </div>

          <div className="mt-8 py-16 bg-white rounded-lg overflow-hidden lg:py-24">
            <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
              <div className="relative">
                <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Your go-to sports app <br />
                  for high school sports
                </h2>
                <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                  The ScoreFeed app puts everything you need in the palm of your
                  hand - whether you're at the game or thinking about what's
                  next.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
