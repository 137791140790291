import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { firestore, db, storage } from '../firebase'
import usePersistedState from '../hooks/usePersistedState'

import { getGameDate } from '../utils/format'
import { getFootballWeek, SupportedStates } from '../utils/data'

import ScoreFeed from '../assets/scorefeed-name-small.png'
import NDScoreFeed from '../assets/ndscorefeed-name-small.png'
import SDScoreFeed from '../assets/sdscorefeed-name-small.png'
import MNScoreFeed from '../assets/mnscorefeed-name-small.png'
import NEScoreFeed from '../assets/nescorefeed-name-small.png'

import { GameByGameOneLine } from './GameByGameOneLine'
import { GameByGameTwoLine } from './GameByGameTwoLine'
import { InvalidOneLine } from './InvalidOneLine'
import { GameByGameHalfWidth } from './GameByGameHalfWidth'
import { GameByGameOneLineHD } from './GameByGameOneLineHD'

export const Ticker = () => {
  const { key, tickerState, tickerSport, tickerType } = useParams()

  const [enabled, setEnabled] = useState(false)
  const [invalidMessage, setInvalidMessage] = useState('')

  const [stateFullName, setStateFullName] = useState('')
  const [logo, setLogo] = useState()
  const [loading, setLoading] = useState(false)

  const [date, setDate] = useState()
  const [dateID, setDateID] = useState()
  const [week, setWeek] = useState('')

  const [currentState, setCurrentState] = usePersistedState(
    'state',
    SupportedStates[3]
  )
  const [currentSport, setCurrentSport] = usePersistedState(
    'sport',
    SupportedStates[3].sports[2]
  )

  const [tz, setTZ] = usePersistedState('timezone', 'America/Chicago')

  const [currentGameDay, setCurrentGameDay] = useState('')
  const [currentTickerStyle, setCurrentTickerStyle] = useState('standard')

  const [mediaOrg, setMediaOrg] = useState({})
  const [games, setGames] = useState([])
  const [allTeams, setAllTeams] = useState([])
  const [allTeamsObj, setAllTeamsObj] = useState({})

  // check if API key is valid and enable
  useEffect(() => {
    setLoading(true)

    const unsubscribe = firestore
      .collection('media')
      .doc('allMedia')
      .onSnapshot(
        function (doc) {
          if (doc.data()) {
            let mediaData = []
            // console.log('doc.data(): ', doc.data())
            doc.data().all.forEach(team => mediaData.push(team))
            console.log(`Received media data: `, mediaData)

            // find key in media data
            const keyIndex = mediaData.findIndex(
              org => org.tickerKey.toUpperCase() === key.toUpperCase()
            ) // find index of key in media data
            console.log('keyIndex: ', keyIndex)
            if (keyIndex !== -1) {
              setMediaOrg(mediaData[keyIndex])
              console.log('mediaOrg: ', mediaData[keyIndex])

              if (mediaData[keyIndex].tickerStatus === 'enabled') {
                setEnabled(true) // enable ticker
                console.log('API key is valid and ticker is enabled')
              } else {
                setInvalidMessage(
                  `${mediaData[keyIndex].name} ticker is not currently available.`
                )
                setLogo(ScoreFeed)
                setEnabled(false) // disable ticker
                console.log('API key is valid but ticker is disabled')
              }
            } else {
              // key not found
              setInvalidMessage(
                'Live games scores and more at your fingertips... Download the ScoreFeed app today.'
              )
              setLogo(ScoreFeed)
              setEnabled(false) // disable ticker
              console.log('API key is not valid')
            }
          }
          setLoading(false)
        },
        err => {
          console.log(`Encountered error receiving media onSnapshot: ${err}`)
        }
      )

    return () => {
      unsubscribe()
    }
  }, [key])

  // set state and sport from URL params
  useEffect(() => {
    if (
      enabled &&
      typeof currentState !== 'undefined' &&
      typeof currentSport !== 'undefined' &&
      typeof tickerState !== 'undefined' &&
      typeof tickerSport !== 'undefined'
    ) {
      console.log('Scores - route API key: ', key)
      console.log('Scores - route tickerState: ', tickerState)
      console.log('Scores - route tickerSport: ', tickerSport)
      console.log('Scores - route tickerType: ', tickerType)

      let theState = {}
      const stateIndex = SupportedStates.findIndex(
        st => st.urlName.toUpperCase() === tickerState.toUpperCase()
      )
      // console.log('State beginning update - currentState: ', currentState)
      if (currentState.urlName !== tickerState) {
        if (stateIndex !== -1) {
          console.log('Route state changed: ', tickerState)
          theState = SupportedStates[stateIndex]
          setCurrentState(theState)
        } else {
          console.log('Route state changed (not valid): ', tickerState)
          theState = SupportedStates[0]
          setCurrentState(theState)
        }
      } else {
        theState = currentState
      }

      let theSport = {}
      const sportIndex = theState.sports.findIndex(
        sp => sp.sport.toUpperCase() === tickerSport.toUpperCase()
      )
      console.log('Sport beginning update - currentSport: ', currentSport)
      console.log('Sport beginning update - sportIndex: ', sportIndex)

      if (currentSport.urlName !== tickerSport) {
        if (sportIndex !== -1) {
          console.log('Route sport changed - sport: ', tickerSport)
          setCurrentSport(theState.sports[sportIndex])
        } else {
          console.log('Route sport changed (not valid): ', tickerSport)
          setCurrentSport(theState.sports[0])
        }
      } else {
        console.log('Route sport updated - sport: ', tickerSport)
        setCurrentSport(theState.sports[sportIndex])
      }
    }

    let fullName = ''
    switch (tickerState) {
      case 'ND':
        fullName = 'North Dakota'
        setLogo(NDScoreFeed)
        break
      case 'MN':
        fullName = 'Minnesota'
        setLogo(MNScoreFeed)
        break
      case 'NE':
        fullName = 'Nebraska'
        setLogo(NEScoreFeed)
        break
      case 'SD':
      default:
        fullName = 'South Dakota'
        setLogo(SDScoreFeed)
        break
    }
    setStateFullName(fullName)

    const today = new Date()
    setDate(today)
    console.log('Ticker - today: ', today)

    const todayDateID = getGameDate(today)
    //setDateID('2025-01-10')
    //setCurrentGameDay('2025-01-10')
    setDateID(todayDateID)
    setCurrentGameDay(todayDateID)
    console.log('Ticker - todayDateID: ', todayDateID)

    setCurrentTickerStyle(tickerType)
  }, [
    enabled,
    key,
    tickerState,
    tickerSport,
    tickerType,
    currentState,
    currentSport
  ])

  // this effect runs every time state or sport or date/week is changed, creating a new snapshot listener for that period
  useEffect(() => {
    if (
      enabled &&
      currentState &&
      currentState.fullName &&
      currentSport &&
      currentSport.sport &&
      currentGameDay
    ) {
      setLoading(true)

      // console.log(
      //   `Creating listener in context for ${currentState.state} ${currentSport.sport} ${currentGameDay}`
      // )

      // clear games array
      setGames([])
      //   setFavGames([]);

      // create snapshot to retrieve and listen for game updates
      let gameDateRef = null
      // console.log('Context db:', db)
      // console.log('Context gameDateRef before: ', gameDateRef)

      gameDateRef = db
        .ref()
        .child('gamesFlat')
        .orderByChild(`${currentState.state}-DateID-Key`)
        .equalTo(
          `2024::${currentState.state}::${currentSport.sport}::${currentGameDay}`
        )

      // console.log('Context gameDateRef after: ', gameDateRef)
      gameDateRef.on('value', handleSnapshot)

      // turn off listener when effect ends
      return () => gameDateRef.off('value', handleSnapshot)
    }
  }, [enabled, currentState, currentSport, currentGameDay])

  function handleSnapshot(snapshot) {
    //console.log("Received snapshot")
    let gamesObj = snapshot.val()
    // console.log('gamesObj: ', gamesObj)

    // convert game objects to an array
    if (gamesObj) {
      setLoading(false)
      const gamesList = []
      const dateList = []

      //  iterate by date
      //iterate through all games for each date and push game to array
      for (const gameKey in gamesObj) {
        gamesList.push(gamesObj[gameKey])
      }

      // console.log('gamesList: ', gamesList)

      //   let favgameList = [];
      // let preGameList = []
      // let liveGameList = []
      // let finishedGameList = []
      // let certifiedGameList = []

      // gamesList.forEach(g => {
      //   // console.log('g: ', g)
      //   if (g.timeRemaining === 'Pregame') {
      //     preGameList.push(g)
      //   } else if (g.certified) {
      //     certifiedGameList.push(g)
      //   } else if (g.timeRemaining.toUpperCase().includes('FINAL')) {
      //     finishedGameList.push(g)
      //   } else {
      //     liveGameList.push(g)
      //   }
      // })

      //   console.log('pregameList: ', pregameList)
      //   console.log('livegameList: ', livegameList)

      // sort any games with notes to the bottom, otherwise sort by time
      // preGameList.sort((a, b) => {
      //   if (a.note === '' && b.note === '') {
      //     if (a.dateTime < b.dateTime) {
      //       return -1
      //     } else {
      //       return 1
      //     }
      //   } else {
      //     if (a.note === '') return -1
      //   }
      // })
      // sort games by time
      // liveGameList.sort((a, b) => {
      //   if (a.dateTime < b.dateTime) {
      //     return -1
      //   } else {
      //     return 1
      //   }
      // })
      // sort games by time
      // finishedGameList.sort((a, b) => {
      //   if (a.dateTime < b.dateTime) {
      //     return -1
      //   } else {
      //     return 1
      //   }
      // })
      // certifiedGameList.sort((a, b) => {
      //   if (a.dateTime < b.dateTime) {
      //     return -1
      //   } else {
      //     return 1
      //   }
      // })

      // set order of display games
      // let theGamesList = Array.from(
      //   new Set(
      //     certifiedGameList
      //       .concat(finishedGameList)
      //       .concat(liveGameList)
      //       .concat(preGameList)
      //   )
      // )

      // filter out games where deleted is true
      let theGamesList = gamesList.filter(g => !g.deleted)

      // sort games by time
      theGamesList.sort((a, b) => {
        if (a.dateTime < b.dateTime) {
          return -1
        } else {
          return 1
        }
      })

      let gamesStarted = theGamesList.filter(g => g.timeRemaining !== 'Pregame')
      // console.log('theGamesList: ', theGamesList)

      if (gamesStarted.length > 0) {
        setGames(gamesStarted)
      } else {
        setGames(theGamesList)
      }

      setLoading(false)
      //console.log("Snapshot empty,")
    }
  }

  // load AllTeams summary data from Firestore document
  useEffect(() => {
    if (
      // Firebase.auth.currentUser !== null &&
      currentState &&
      currentState.fullName &&
      currentSport &&
      currentSport.sport
    ) {
      setLoading(true)
      // console.log(`loading ${currentSport.sport} data`)
      // console.log('db: ', db)
      // console.log('firestore: ', firestore)
      const unsubscribe = firestore
        .collection(currentState.fullName)
        .doc('2024')
        .collection(`${currentSport.sport}teams`)
        .doc('2024-AllTeams')
        .onSnapshot(
          function (doc) {
            if (doc.data()) {
              let teamData = []
              // console.log('doc.data(): ', doc.data())
              doc.data().all.forEach(team => teamData.push(team))
              // console.log(
              //   `Received ${currentState.fullName} ${currentSport.sportName} data for allTeams: `,
              //   teamData
              // )

              // setFBTeams(teamData)
              setAllTeams(teamData)
            }
            setLoading(false)
          },
          err => {
            console.log(
              `Encountered error receiving ${currentSport.sport} teams onSnapshot: ${err}`
            )
          }
        )

      return () => {
        unsubscribe()
      }
    }
  }, [currentState, currentSport])

  // create teams objects with team name properties for fast lookup
  useEffect(() => {
    const theAllTeamsObj = {}

    // get team records and rank
    allTeams.forEach(team => {
      theAllTeamsObj[team.team] = {
        displayName: team.displayName,
        urlName: team.team.replace(/\s/g, ''),
        rank: team.rank,
        record: `${team.wins}-${team.losses}${
          team.ties > 0 ? `-${team.ties}` : ''
        }`
      }
    })
    setAllTeamsObj(theAllTeamsObj)
    // console.log('allTeamsObj: ', theAllTeamsObj)

    // set options array for use in selecting teams
    // const options = allTeams.map(team => {
    //   return { value: team.team, label: team.team }
    // })
    // setAllTeamsOptions(options)
  }, [allTeams])

  return (
    <>
      {/* Display message if ticker is disabled */}
      {!enabled && invalidMessage && (
        <InvalidOneLine message={invalidMessage} logo={logo} />
      )}
      {/* Display selected ticker */}
      {enabled && tickerType === 'gamebygame-oneline' && (
        <GameByGameOneLine
          games={games}
          sport={currentSport}
          allTeamsObj={allTeamsObj}
          logo={logo}
          mediaOrg={mediaOrg}
          date={date}
          loading={loading}
        />
      )}
      {enabled && tickerType === 'gamebygame-oneline-hd' && (
        <GameByGameOneLineHD
          games={games}
          sport={currentSport}
          allTeamsObj={allTeamsObj}
          logo={logo}
          mediaOrg={mediaOrg}
          date={date}
          loading={loading}
        />
      )}
      {enabled && tickerType === 'gamebygame-twoline' && (
        <GameByGameTwoLine
          games={games}
          sport={currentSport}
          allTeamsObj={allTeamsObj}
          logo={logo}
          mediaOrg={mediaOrg}
          date={date}
          loading={loading}
        />
      )}
      {enabled && tickerType === 'gamebygame-halfwidth' && (
        <GameByGameHalfWidth
          games={games}
          sport={currentSport}
          allTeamsObj={allTeamsObj}
          logo={logo}
          mediaOrg={mediaOrg}
          date={date}
          loading={loading}
        />
      )}
    </>
  )
}
