import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/storage'
// import 'firebase/analytics'

// development environment
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_DEV_API_KEY,
//   authDomain: process.env.REACT_APP_DEV_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DEV_BASEURL,
//   projectId: process.env.REACT_APP_DEV_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_DEV_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_DEVAPP_ID,
//   measurementId: process.env.REACT_APP_DEV_MEASUREMENT_ID
// }

// production environment
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_BASEURL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

// Initialize Firebase only once
const initFirebase = () => {
  if (!firebase.apps.length) {
    // console.log('Initializing Firebase')
    firebase.initializeApp(firebaseConfig)
  }
  // console.log('firebase: ', firebase)
}

initFirebase()

const auth = firebase.auth()
const firestore = firebase.firestore()
const db = firebase.database()

// console.log('firebase', firebase)
// console.log('db', db)

const storage = firebase.storage()
const adStorage = firebase.storage().ref('ads')

// const analytics = firebase.analytics()

const createTimestamp = firebase.firestore.FieldValue.serverTimestamp
const serverTimestamp = firebase.database.ServerValue.TIMESTAMP

export {
  firebase,
  auth,
  firestore,
  db,
  storage,
  adStorage,
  createTimestamp,
  serverTimestamp
}
