import format from 'date-fns/format'

// return datetime with date no matter what timezone
export const getDateOnly = d => {
  const dt = new Date(d)
  // Tue Dec 11 2017 19:00:00 GMT-0500 (EST)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
  // Tue Dec 12 2017 00:00:00 GMT-0500 (EST)
  return dtDateOnly
}

export const getGameDate = d => {
  return format(d, 'yyyy-MM-dd')
}
