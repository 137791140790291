import React, { useState, useEffect } from 'react'

import usePersistedState from '../hooks/usePersistedState'

import { parseISO } from 'date-fns'
import { format } from 'date-fns-tz'

import { Transition } from '@headlessui/react'

const DISPLAY_INTERVAL = 7000

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const GameByGameOneLineHD = props => {
  const { games, sport, allTeamsObj, logo, mediaOrg, date, loading } = props

  const [currentGameIndex, setCurrentGameIndex] = usePersistedState(
    'gameIndex',
    0
  )

  //   const [displayGames, setDisplayGames] = useState([])
  const [game, setGame] = useState(null)
  const [haveGames, setHaveGames] = useState(false)
  const [show, setShow] = useState(false)

  const [mediaLogoLoaded, setMediaLogoLoaded] = useState(false)
  const [logoClicked, setLogoClicked] = useState(false)

  const [rank1, setRank1] = useState('')
  const [rank2, setRank2] = useState('')
  const [record1, setRecord1] = useState('')
  const [record2, setRecord2] = useState('')

  // set the next game to display
  const setNextGame = () => {
    // if there are games, set the next game to display
    if (games.length > 0) {
      // if there are more games to display, set the next game
      if (currentGameIndex < games.length) {
        setGame(games[currentGameIndex])
        setCurrentGameIndex(prev => prev + 1)
        setShow(true)
      } else {
        // if there are no more games to display, set the first game
        setGame(games[0])
        //
        if (games.length > 1) {
          setCurrentGameIndex(1)
        } else {
          setCurrentGameIndex(0)
        }
        setShow(true)
      }
    }
  }

  // set the first game to display when the games array is populated
  useEffect(() => {
    console.log('games', games)

    if (games.length > 0 && !haveGames) {
      setHaveGames(true)
      setNextGame()
    }
  }, [games])

  // timer to set the element display interval
  useEffect(() => {
    if (games.length > 0) {
      const interval = setInterval(() => {
        setNextGame()
      }, DISPLAY_INTERVAL)

      // clean up
      return () => clearInterval(interval)
    }
  }, [currentGameIndex])

  // timer to show and hide elements - toggling 'show' triggers Transition enter and leave
  // timer should be ~1 second less than element display interval
  useEffect(() => {
    const interval = setInterval(() => {
      if (show) {
        setShow(false)
      }
    }, DISPLAY_INTERVAL - 1000)

    // clean up
    return () => clearInterval(interval)
  }, [show])

  const logoClick = () => {
    console.log('logo clicked')
    setLogoClicked(!logoClicked)
  }

  useEffect(() => {
    if (game) {
      // set record and rank
      if (game.team1 in allTeamsObj) {
        setRank1(allTeamsObj[game.team1].rank)
        setRecord1(allTeamsObj[game.team1].record)
      } else {
        setRank1('')
        setRecord1('')
      }

      if (game.team2 in allTeamsObj) {
        setRank2(allTeamsObj[game.team2].rank)
        setRecord2(allTeamsObj[game.team2].record)
      } else {
        setRank2('')
        setRecord2('')
      }
    }
  }, [game])

  return (
    <>
      <div className="w-full h-10 flex justify-between overflow-hidden text-sm font-normal text-white  bg-gray-900  ">
        {/* Left side content  */}
        <div className="flex">
          {/* Ticker logo / image */}
          <div className="flex items-center w-48 ml-0 bg-blue-700 border-t border-blue-600">
            <img
              src={logo}
              className=" px-3"
              alt="logo"
              onClick={() => logoClick()}
            />
          </div>
          {/* Ticker information wrapper */}
          <div className="flex items-center border-t border-gray-800">
            {/* Sport */}
            <div className="w-28 px-2 text-lg tracking-wide">{`${sport.sport}`}</div>
            {/* Game  */}
            {game && (
              <div className="flex h-full whitespace-nowrap">
                {/* vertical line */}
                <div className="mt-1 mb-1 border-r border-gray-300" />
                {/* Team 1 */}
                <div className="w-128 px-4 flex flex-col justify-center">
                  {/* Team 1 top line */}
                  <Transition show={show}>
                    <Transition.Child
                      enter="transform ease-out duration-150 transition"
                      enterFrom="translate-y-full opacity-50"
                      enterTo=" translate-y-0 opacity-100"
                      leave="ease-in duration-150 transition"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className={classNames(
                        game.team1score > game.team2score
                          ? 'text-yellow-200'
                          : 'text-white',
                        'flex justify-between items-center tracking-wide leading-tight whitespace-normal'
                      )}
                    >
                      <div className="flex items-baseline">
                        <div className="text-lg mr-1">{rank1}</div>
                        <div className="text-2xl">{game.team1displayName}</div>
                      </div>
                      <div className="text-3xl">
                        {game.timeRemaining === 'Pregame'
                          ? ''
                          : game.team1score}
                      </div>
                    </Transition.Child>
                  </Transition>
                </div>
                {/* vertical line */}
                <div className="mt-1 mb-1 border-r border-gray-300" />
                {/* Team 2 */}
                <div className="w-128 px-4 flex flex-col justify-center">
                  {/* Team 2 top line */}
                  <Transition show={show}>
                    <Transition.Child
                      enter="transform ease-out duration-150 transition"
                      enterFrom="translate-y-full opacity-50"
                      enterTo="translate-y-0 opacity-100"
                      leave="ease-in duration-150 transition"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className={classNames(
                        game.team2score > game.team1score
                          ? 'text-yellow-200'
                          : 'text-white',
                        'flex justify-between items-center tracking-wide leading-tight whitespace-normal'
                      )}
                    >
                      <div className="flex items-baseline">
                        <div className="text-lg mr-1">{rank2}</div>
                        <div className="text-2xl">{game.team2displayName}</div>
                      </div>
                      <div className="text-3xl">
                        {game.timeRemaining === 'Pregame'
                          ? ''
                          : game.team2score}
                      </div>
                    </Transition.Child>
                  </Transition>
                </div>
                {/* vertical line */}
                <div className="mt-1 mb-1 border-r border-gray-300" />
                {/* Game status */}
                <div className="w-56 px-4 flex items-center">
                  {/* Game status top line */}
                  <Transition show={show}>
                    <Transition.Child
                      enter="transform ease-out duration-150 transition"
                      enterFrom="translate-y-full opacity-50"
                      enterTo="translate-y-0 opacity-100"
                      leave="ease-in duration-150 transition"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="text-xl"
                    >
                      {game.timeRemaining === 'Pregame'
                        ? game.dateTime === '' ||
                          format(
                            parseISO(game.dateTime),
                            'E MMM d, h:mm bb'
                          ).includes('midnight')
                          ? ''
                          : format(parseISO(game.dateTime), 'h:mm a')
                        : game.timeRemaining}
                      {` ${game.note}`}
                    </Transition.Child>
                  </Transition>
                  {/* Game status bottom line */}
                  <div className="text-gray-400 overflow-hidden"></div>
                </div>
              </div>
            )}
            {/* Display message if no games available */}
            {!game && (
              <div className="text-base">{`No games scheduled for ${format(
                date,
                'iiii, MMM d, yyyy'
              )}`}</div>
            )}
          </div>
        </div>
        {/* Right side content */}
        <div className="flex">
          {/* vertical line */}
          <div className="border-r border-yellow-300" />
          {/* Advertisement  */}
          {/* <div className="w-96 bg-gray-800"></div> */}
          <div className="flex items-center ml-0 bg-black border-t border-blue-600">
            {mediaOrg.tickerLogo && (
              <img
                src={mediaOrg.tickerLogo}
                className={
                  mediaLogoLoaded ? 'h-9 px-3 object-contain' : 'hidden'
                }
                alt="logo"
                onClick={() => logoClick()}
                onLoad={() => setMediaLogoLoaded(true)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
