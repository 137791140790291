import { useEffect, useState, useContext, createContext } from 'react'
import { auth, firestore, createTimestamp } from '../firebase'

const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  // authUser contains properties from firebase auth
  const [authUser, setAuthUser] = useState(null)
  // user contains all properties from firebase auth and user document
  const [user, setUser] = useState(null)

  const [authLoading, setAuthLoading] = useState(true)
  const [updateUser, setUpdateUser] = useState(false)
  const [okayToLogInAnonymous, setOkayToLogInAnonymous] = useState(true)

  // values set when new user signs up, to be used after confirming verification code
  const [signupName, setSignupName] = useState('')
  const [signupEmail, setSignupEmail] = useState('')

  // update authUser whenever auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userFromAuth) => {
      if (userFromAuth) {
        console.log(
          `Auth state changed: Logged in ${
            userFromAuth.isAnonymous ? 'anonymous' : 'registered'
          } user from auth:`,
          userFromAuth
        )
        setAuthUser({
          uid: userFromAuth.uid,
          isAnonymous: userFromAuth.isAnonymous,
          displayName: userFromAuth.displayName,
          phoneNumber: userFromAuth.phoneNumber,
          email: userFromAuth.email,
          emailVerified: userFromAuth.emailVerified,
        })

        // set user in Analytics
        // Analytics.setUserId(authUser.uid);

        setUpdateUser(false)
        setAuthLoading(false)
      } else {
        console.log('Auth state changed: Not logged in...')
        if (okayToLogInAnonymous) {
          console.log('Signing in anonymously')
          auth.signInAnonymously().catch((error) => {
            console.log('sign in anonymously error: ', error)
          })
          // set user in Analytics
          // Analytics.setUserId(authUser.uid);
        } else {
          console.log(
            'Not okay to sign in anonymously, so bypassing automatic sign in anonymously'
          )
        }
      }
    })

    return () => {
      unsubscribe()
    }
  }, [okayToLogInAnonymous, updateUser])

  // when authUser changes, get info from User document
  useEffect(() => {
    try {
      // whenever we are logged in as a registered user or anonymousely
      if (auth.currentUser !== null) {
        const initialUserDocument = {
          uid: authUser.uid,
          isAnonymous: authUser.isAnonymous,
          displayName: signupName,
          phoneNumber: authUser.phoneNumber,
          email: signupEmail,
          emailVerified: authUser.emailVerified,
          favorites: [],
          isBlocked: false,
          isAdmin: false,
          adminRoles: {},
          isSportManager: false,
          sportManagerRoles: [],
          requests: {},
          isTeamManager: false,
          teamManagerRoles: {},
          devices: [],
        }
        // get user document and set user state
        const ref = firestore.collection('users').doc(authUser.uid)
        ref.get().then((doc) => {
          if (doc.exists) {
            console.log('User doc exists')
            const userDoc = doc.data()

            // update user state
            if (!authUser.isAnonymous && userDoc.isAnonymous) {
              console.log('Upgrading an anonymous user doc to new user doc')
              // set user if anonymous -> new (write user doc with registered timestamp)
              setUser(initialUserDocument)
              ref.set({
                ...initialUserDocument,
                created: userDoc.created,
                registered: createTimestamp(),
              })
            } else {
              console.log(
                `Using an existing ${
                  userDoc.isAnonymous ? 'anonymous' : 'registered'
                } user doc`
              )
              // set user if anonymous -> existing (no need to write user doc)
              setUser({
                ...initialUserDocument,
                ...userDoc,
              })
            }
          } else {
            console.log('User doc does not exist')
            // set new anonymous user (write initial user document with created timestamp)
            console.log('Creating new anonymous user doc')
            setUser(initialUserDocument)
            ref.set({ ...initialUserDocument, created: createTimestamp() })
          }
        })
      }
    } catch (error) {
      console.log('Error getting user document: ', error)
    }
  }, [authUser, signupName, signupEmail])

  // log out
  const logout = () => {
    console.log('Signing out...')

    // reset user state
    // setAuthUser(null)
    // setUser(null)
    setSignupName('')
    setSignupEmail('')

    // sign out
    auth.signOut()
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        authLoading,
        signupName,
        setSignupName,
        signupEmail,
        setSignupEmail,
        logout,
        okayToLogInAnonymous,
        setOkayToLogInAnonymous,
        setUpdateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  return useContext(AuthContext)
}

export { AuthProvider, useAuth }
